'use client'

import { InlineWidget } from "react-calendly"

function calendly(){

    return(
        <InlineWidget url="https://calendly.com/rdvconatct" iframeTitle="Planifier un rendez-vous de 30 minutes avec nous, avec l'outil Calendly" styles={{height: '600px',width: '500px'}}/>
    )
}

export default calendly;