"use client";

import Minus from "../../../../public/svg/minus.svg";
import Plus from "../../../../public/svg/plus.svg";
import { useState } from "react";

export interface LandingTravaillerAvecNousProps {
  title: string;
  content: string;
}

function LandingTravaillerAvecNous({ sections }: { sections: LandingTravaillerAvecNousProps[] }) {
  const [openSection, setOpenSection] = useState<number | null>(null);

  const toggleSection = (index: number) => {
    setOpenSection((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      {sections.map((section, index) => {
        const isOpen = openSection === index;
        const contentId = `accordion-panel-${index}`;
        const headingId = `accordion-heading-${index}`;

        return (
          <div key={index}>
            <div className="mt-3 mb-3 bg-foreground h-px md:w-3/4 m-0" />

            <h4
              className="text-lg text-left flex-grow md:w-8/12 md:flex-none"
              id={headingId}
            >
              <button
                className="flex items-center mb-2 w-full"
                onClick={() => toggleSection(index)}
                aria-expanded={isOpen ? "true" : "false"}
                aria-controls={contentId}
              >
                {section.title}

                <div className="flex justify-end md:flex-none" style={{ marginLeft: "auto" }}>
                  {isOpen ? (
                    <Minus stroke="var(--foreground-color)" height="25px" width="25px" />
                  ) : (
                    <Plus stroke="var(--foreground-color)" height="25px" width="25px" />
                  )}
                </div>
              </button>
            </h4>

            <div
              id={contentId}
              role="region"
              aria-labelledby={headingId}
              className={`overflow-hidden transition-[max-height] ease-in-out ${
                isOpen ? "max-h-40" : "max-h-0"
              }`}
            >
              <p className="md:w-3/4 text-foreground-900">{section.content}</p>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default LandingTravaillerAvecNous;
